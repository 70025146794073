import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Senior Software Engineer" />
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
      className="col-small-row-large"
    >
      <StaticImage
        src="../images/sander_forest.png"
        alt="A photo of me, Sander, from the waist up. I'm wearing a white button-down shirt that is untucked, with round, brown glasses. The background consists of a path in a forest-like environment."
        imgStyle={{ maxWidth: "510px" }}
      ></StaticImage>
      <div style={{ marginLeft: "1rem" }}>
        <p>
          Hi! I'm Sander Philipse, a software engineer from Amsterdam. I'm
          currently building cool stuff at{" "}
          <a href="https://www.zivver.com/">Zivver</a>.
        </p>
        <p>
          I'm also a historian and occasionally write on racism in Dutch society
          and colonial history, mostly in Dutch. I've been published in national
          newspapers and international online magazines. You can find some of my
          writing on <a href="https://medium.com/@sanderphilipse">Medium</a> and{" "}
          <a href="https://www.oneworld.nl/personen/sander-philipse/">
            OneWorld
          </a>
          .
        </p>
          <h3>Find me elsewhere</h3>
          <ul>
            <li>
              <a href="https://github.com/sanderphilipse">GitHub</a>
            </li>
            <li>
              <a href="mailto:sanderphilipse@gmail.com">
                sanderphilipse@gmail.com
              </a>
            </li>
            <li>
              <a href="https://twitter.com/sanderphilipse">Twitter</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/sander-philipse-524100173/">
                LinkedIn
              </a>
            </li>
          </ul>
      </div>
    </div>
  </Layout>
)

export default IndexPage
